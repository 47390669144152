import { uuid } from 'vue-uuid';
import { map, capitalize } from 'lodash';
import { httpPayin } from './http';
import gamesHeaders from './headers';

export default {
  getParams(config, providerBetslipId = null) {
    const { platformName, applicationName } = config;
    const params = {
      seven: {
        web: {
          requestUuid: uuid.v4(),
          deviceUuid: uuid.v4(),
        },
        mobile: {
          requestUuid: uuid.v4(),
          deviceUuid: uuid.v4(),
        },
        terminal: {
          requestUuid: uuid.v4(),
        },
      },
      nextGen: {
        web: {
          requestUuid: providerBetslipId,
        },
        mobile: {
          requestUuid: providerBetslipId,
        },
        terminal: {

        },
      },
      playtech: {
        web: {
        },
        terminal: {
        },
      },
    };
    return params[platformName][applicationName.toLowerCase()] || {};
  },
  createMeta(config, user, params) {
    const platform = config.platformName;
    const sources = [
      {
        type: 'player',
        uuid: user.profile.id,
      },
      {
        type: 'productInstance',
        uuid: config.productUuid ? config.productUuid : config.productId,
      },
    ];
    const metaData = {
      seven: {
        product: config.productFqn,
        deliveryPlatform: capitalize(config.applicationName),
        cpvUuid: config.productId,
        paymentMethod: 'VirtualMoney',
        requestUuid: params.requestUuid,
        appDeviceUuid: params.deviceUuid, // TODO
        sources,
      },
      playtech: {
        player: {
          firstname: '',
          lastname: '',
          username: user.profile.id || user.profile.username,
          uuid: user.profile.id || user.profile.username,
          isThirdParty: false,
        },
      },
      nextGen: {
        requestUuid: params.requestUuid,
        deliveryPlatform: capitalize(config.applicationName),
        b2bVal: config.b2bVal,
        platformVal: config.platformVal,
        punterToken: user.auth.token,
        player: {
          firstname: user.profile.firstname,
          lastname: user.profile.lastname,
          username: user.profile.username,
          uuid: user.profile.id,
        },
      },
    };
    return metaData[platform];
  },
  createTicket(betslip, formatTicketBets, user) {
    const {
      ticket,
      payment,
      ticketType,
      bonuses,
    } = betslip;
    return {
      bets: formatTicketBets(ticket),
      payin: payment,
      ticketComment: 'Prva Firma', // TODO
      ticketType, // TODO
      ticketOddsOptions: 2, // TODO,
      isFreeBet: !!user?.freeBets?.count || false,
      bonuses,
    };
  },
  formatTicketBets(tickets) {
    return map(tickets, ticket => ({
      type: ticket.outcomeId,
      value: [ticket.id],
    }));
  },
  formatBetsForPlatform(ticket, platform) {
    // REASON FOR THIS IS REFACTORING ON BACKEND
    // CURRENT BACKEND IMPLEMENTATION FOR THESE PLATFORMS DIVIDES BET PAYIN WITH NUMEVENTS
    // THIS IS DONE ON BOTH PER BET AND PER TICKET STRATEGIES
    // MORE ABOUT THIS IN TASK 86980jur8
    if (platform === 'PLAYTECH' || platform === 'NEXTGEN') {
      // eslint-disable-next-line no-param-reassign
      ticket.bets = ticket.bets.map(bet => ({
        ...bet,
        payin: bet.payin * bet.numEvents,
      }));
    }

    return ticket;
  },
  payin(config, user, betslip, formatTicketBets = this.formatTicketBets, additionalInfo) {
    const { providerBetslipId } = betslip || null;
    const headers = {
      ...gamesHeaders.getAuthHeader(user, config),
      ...gamesHeaders.getPlatformHeaders(user, config, providerBetslipId),
    };
    const platform = config.platformName.toUpperCase();
    const path = config.applicationName === 'Terminal'
      ? process.env.VUE_APP_TICKET_PAYIN_TERMINAL_PATH
      : process.env[`VUE_APP_${platform}_TICKET_PAYIN_PATH`];

    const params = this.getParams(config, providerBetslipId);

    const ticket = this.createTicket(betslip, formatTicketBets, user);

    const formattedTicket = this.formatBetsForPlatform(ticket, platform);

    const data = {
      additionalInfo,
      metadata: config.applicationName === 'Terminal' ? null : this.createMeta(config, user, params),
      ticket: formattedTicket,
    };
    if (config.applicationName === 'Terminal') {
      return data;
    }
    return httpPayin(config).post(path, data, { params, headers });
  },
  // When game passes ticket payload
  genericPayin(config, payload) {
    const { platformName } = config;

    const path = process.env.VUE_APP_TICKET_PAYIN_PATH;
    return httpPayin(platformName).post(path, payload);
  },
};
