<template>
  <div class="buttons-grid">
    <div class="row-wrapper"
         v-for="(row, rowIndex) in numberOfRows"
         :key="rowIndex">
      <div class="row">
        <div v-for="(column, columnIndex) in numberOfColumns"
             :class="[variation, typeClass]"
             class="button-wrapper"
             :key="columnIndex">
          <BingoGridButton @active="toggleButton(getButton(rowIndex, columnIndex))"
                           :label="getButton(rowIndex, columnIndex).value"
                           :variation="variation"
                           :class="typeClass"
                           :color="getButton(rowIndex, columnIndex).hex"
                           :active="isSelectedButton(getButton(rowIndex, columnIndex))" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  find,
  concat,
  filter,
  dropRight,
  isNil,
} from 'lodash';
import { typeable } from '../mixins';
import BingoGridButton from './BingoGridButton';

export default {
  name: 'ButtonsGrid',
  mixins: [typeable],
  components: {
    BingoGridButton,
  },
  props: {
    numberOfRows: {
      type: Number,
      default: 0,
    },
    numberOfColumns: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default: () => [],
    },
    variation: {
      type: String,
      default: 'square',
    },
    buttonsList: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    maxSelection: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      listOfSelectedButtons: [],
    };
  },
  computed: {
    ...mapGetters(['isAudioOn']),
  },
  methods: {
    getButton(rowIndex, columnIndex) {
      const index = this.direction === 'horizontal'
        ? rowIndex * this.numberOfColumns + columnIndex
        : rowIndex + this.numberOfRows * columnIndex;

      return this.buttonsList[index];
    },
    toggleButton(currentButton) {
      const isSelectedButton = this.isSelectedButton(currentButton);

      if (isSelectedButton) {
        if (this.isAudioOn && this.$AudioManager) {
          this.$AudioManager.play('deselect');
        }
        this.listOfSelectedButtons = filter(
          this.listOfSelectedButtons,
          selectedButton => selectedButton.value !== currentButton.value,
        );
      } else {
        if (this.isAudioOn && this.$AudioManager) {
          this.$AudioManager.play('select');
        }
        this.listOfSelectedButtons = concat(this.listOfSelectedButtons, currentButton);
      }

      if (this.listOfSelectedButtons.length > this.maxSelection) {
        this.listOfSelectedButtons = dropRight(this.listOfSelectedButtons, 1);
        this.$emit('maxSelectionReached');
      }

      this.$emit('change', this.listOfSelectedButtons);
    },
    isSelectedButton(currentButton) {
      return Boolean(
        find(
          this.listOfSelectedButtons,
          selectedButton => selectedButton.value === currentButton.value,
        ),
      );
    },
  },
  created() {
    if (!isNil(this.value)) {
      this.listOfSelectedButtons = concat(this.listOfSelectedButtons, this.value);
    }
  },
  watch: {
    value(v) {
      this.listOfSelectedButtons = v;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-grid {
  .row-wrapper:first-child {
    .button-wrapper {
      &.square {
        margin: 0 1px 1px 1px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .row-wrapper {
    .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
.button-wrapper {
  display: inline-block;
  margin: 4px;

  &.square {
    margin: 1px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.circle {
    margin: 0 8px 8px 0;
  }

  &.type-terminal {
    &.circle {
      margin: 0 8px 8px 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.type-terminal-large {
    &.circle {
      margin: 0 16px 16px 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 660px) {
  .row {
    display: flex;
    justify-content: center;
    .button-wrapper {
      &.circle {
        margin: 0 3px 3px 0;
      }
    }
  }
}
</style>
