<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="last-tickets-item"
    :class="{'ticket-details': ticketDetailItem}">
    <!-- HEADER - TICKET -->
    <div class="footer"
         v-if="showFooter && ticket.bets.length > 1"
         @mouseover="toggleRebet(true)"
         @mouseleave="toggleRebet(false)"
    >
      <!-- TICKET STATUS -->
      <div class="ticket-status">
        <div class="value"
             :class="`status-${ticket.status.value.toLowerCase()}`">
          {{ betStatusLabel(ticket.status) }}
        </div>
      </div>
      <!-- TICKET PAYOUT -->
      <div class="payout"
           v-if="ticket.payout">
        <div class="value"
             :class="`status-${ticket.status.value.toLowerCase()}`">
          {{': '}} {{ ticket.payout.toFixed(2) }}
        </div>
      </div>
      <!-- REBET INFO -->
      <Transition name="fade">
        <div class="rebet"
             v-if="ticketRebetAllowed && !isFreeBetMode"
             v-show="rebetVisible"
             @click="doRebet">
          <span>
            {{ translations.general_rebet }}
          </span>
        </div>
      </Transition>
    </div>
    <!-- BODY - BETS -->
    <div class="body">
      <div v-if="receivedIsLastTicketsData">
        <div class="bet-item"
             @click="!clickDisabled && setTicketDetails(ticket)"
             @mouseover="toggleRebet(true)"
             @mouseleave="toggleRebet(false)"
             v-for="bet in ticket.bets"
            :class="{
              'multiline-bet': isMultilineBet(bet),
              'free-bet': ticket.bonuses || ticket.isFreeBet,
              'active': isTicketActive(ticket.status.value)
              }"
             :key="bet.id">
          <div class="bet-value"
               :class="`status-${statusPosition}`">
            <div class="status"
                 :class="`status-${bet.status.value.toLowerCase()}`">
              <div class="label bet-status"
                :class="{'super-bonus': ticket.superBonus && bet.status.value !== 'LOST'}">
                <span v-if="ticket.bets.length > 1 && !ticketDetailItem">
                  {{ betStatusLabel(bet.status) }}
                </span>
                <span v-if="ticket.bets.length > 1 && ticketDetailItem">
                  {{ ticketDetailBetStatusLabel(bet.status.value) }}
                </span>
                <span v-if="ticket.bets.length === 1 && !ticketDetailItem">
                  {{ betStatusLabel(bet.status) }}
                </span>
                <span
                  v-if="ticket.bets.length === 1 && ticketDetailItem"
                  :class="{'lost': bet.status.value === 'LOST' }">
                  {{ ticketDetailBetStatusLabel(bet.status.value) }}
                </span>
                <span v-if="isDefined(ticket.payout) && ticket.payout !== 0
                  && bet.status.value === 'PAYEDOUT'
                  && ticket.bets.length === 1 && !ticketDetailItem">
                  {{ ': ' }}{{ ticket.payout.toFixed(2) }}</span>
              </div>
              <Transition name="fade">
                <div class="rebet"
                     v-if="ticketRebetAllowed && !isFreeBetMode"
                     v-show="rebetVisible && ticket.bets.length === 1"
                     @click.stop="doRebet">
                  <span>{{ translations.general_rebet }}</span>
                </div>
              </Transition>
            </div>
            <div>
              <div class="label subtitle"
                :class="{'ticket-details': ticketDetailItem}">
                <span class="normal">
                  {{ translations.general_round }}{{': '}}
                </span>
                <span class="bolded">
                  {{ bet.round }}
                </span> |
                <span class="normal">
                  {{ betTypeLabel(bet.market, bet) }}
                </span>
              </div>
              <div class="outcome-wrap">
                <div class="label title bet-outcome">
                     <div v-if="isNumbersBet(bet)" class="outcome-wrapper">
                      <BingoGridButton
                      v-for="(ball, index) in filteredOutcome(bet)"
                      :key="index"
                      :variation="gridVariation"
                      :active="isBallActive(ball, bet)"
                      :color="getBallColor(ball)"
                      :label="ball"
                      />
                    </div>
                    <div v-if="isNumbersBet(bet)" class="placeholders">
                      <BingoGridButton
                      v-for="(ball, index) in filteredOutcome(bet)"
                      :key="index"
                      :variation="gridVariation"
                      :active="false"
                      :color="getBallColor(ball)"
                      :label="ball"
                      :disabled="true"
                      />
                    </div>
                     <span v-else> {{ bet.outcome }}</span>
                </div>
                <div class="bet-odd">
                  <span v-if="statusPosition === 'right'
                    && isDefined(bet.odd) && bet.type !== 12">
                      {{bet.type !== 13 ? '(' : '' }}{{getOdd(bet)}}{{ bet.type !== 13 ? ')' : ''}}
                  </span>
                </div>
              </div>
              <div class="label title label-last" v-if="isDetailsMode && !isComboTicket">
                <span>{{ `${translations.general_stake} : ` }}</span>
                <span>{{ bet.stake.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else
           class="bet-item"
           :class="{
            'free-bet': ticket.bonuses || ticket.isFreeBet,
            'seven-platform': sevenPlatform,
           }"
           @click="!clickDisabled && setTicketDetails(ticket)">
        <div v-if="!sevenPlatform" class="bet-value">
          <div class="label status"
               :class="`status-${ticket.status.value.toLowerCase()}`">
            <span>{{ betStatusLabel(ticket.status) }}</span>
            <span v-if="isDefined(ticket.payout) && ticket.payout !== 0">
              {{ ': ' }}{{ ticket.payout.toFixed(2) }}
            </span>
          </div>
          <div class="label label-last subtitle">
            <span>
              {{ translations.general_stake }}{{ ': ' }}
            </span>
            <span v-if="isDefined(ticket.payin)"
                  class="bolded">{{ ticket.payin.toFixed(2) }}</span>
            <div class="date-time-wrapper">
              <span> | </span>
              <span>
                {{ getDateTime(ticket.createdAt) }}
              </span>
            </div>
          </div>
        </div>
        <div class="ticket-header"
             :class="`border-left-color-status-${ticket.status.value.toLowerCase()}`"
             v-else>
          <div class="ticket-header-left">
            <div>{{productNameLabel}}</div>
            <div>
        <span class="ticket-header-status"
              :class="`status-${ticket.status.value.toLowerCase()}`">
          {{translations[`general_ticket_status_${ticket.status.value.toLowerCase()}`]}}
        </span>
              <span class="opacity-primary">
          {{formatDate}}
        </span>
            </div>
          </div>
          <div class="ticket-header-right">
            <div class="ticket-header-payin-wrapper">
              <div class="ticket-header-payin">
          <span class="opacity-primary">
            {{translations['general_pay_in']}}
          </span>
                <span class="opacity-primary">
            {{formatNumber(ticket.payin)}}
          </span>
              </div>
              <div v-if="ticket.payinTax"
                   class="ticket-header-tax">
          <span class="opacity-primary">
            {{translations['general_payin_tax']}}
          </span>
                <span class="opacity-primary">
            {{formatNumber(ticket.payinTax)}}
          </span>
              </div>
            </div>
            <div class="ticket-header-payout-wrapper">
              <div class="ticket-header-payout"
                   :class="{'opacity-primary': !ticket.payout}">
          <span>
            {{translations['general_ticket_status_paidout']}}
          </span>
                <span>
            {{formatNumber(ticket.payout)}}
          </span>
              </div>
              <div v-if="ticket.payoutTax"
                   class="ticket-header-tax">
          <span>
            {{translations['general_payout_tax']}}
          </span>
                <span>
            {{formatNumber(ticket.payoutTax)}}
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bonus-wrapper"
         v-if="ticket.superBonus && !isDetailsMode">
      <span>
        {{ ticket.superBonus.name }}
        {{` +${ticket.superBonus.amount.toFixed(2)}`}}
      </span>
    </div>
    <LastTicketDetails v-if="isIndependable"
                       @closeDetails="toggleModal(false)"
                       :isBallDrawn="isBallDrawn"
                       :ticket="ticket"
                       :productNameLabel="productNameLabel"
                       :isModalVisible="isModalVisible"
                       :isLastTicketDeta="toPassIsLastTicketsData" />
    <TicketsHistoryDetailsAccordion v-else
                                    @closeDetails="closeAccordion"
                                    :ticket="ticketDetails"
                                    :isExpanded="isAccordionExpanded"
                                    :accordion-header="accordionHeader"
                                    :is-close-button-allowed="isCloseButtonAllowed"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  isArray, isNil, includes, toLower,
} from 'lodash';
import { format, parseISO } from 'date-fns';
import TicketsHistoryDetailsAccordion from '../../TicketsHistory/TicketsHistoryDetailsAccordion';
import LastTicketDetails from './LastTicketsDetails';
import { eventBus, capitalizeFirstLetter } from '../../../utility';
import BingoGridButton from '../../BingoGridButton';

export default {
  name: 'LastTicketsBetItem',
  components: {
    LastTicketDetails,
    TicketsHistoryDetailsAccordion,
    BingoGridButton,
  },
  props: {
    clickDisabled: {
      type: Boolean,
      default: false,
    },
    ticketDetailItem: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default: () => ({
        id: '',
        status: {
          id: '',
          name: '', // status text
          value: '', // status type
        },
        payout: '',
        round: '',
        bets: [
          {
            id: '',
            status: {
              name: '', // status text
              value: '', // status type
            },
            odd: '',
            round: '', // current eventId
            market: 'standard', // current typeValue
            outcome: '', // todo check selected balls
            eventValue: '', // bingo games - drawn balls
          },
        ],
      }),
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    // Since we are using this component recursively,
    // (LTBetItem -> LTDetails -> LTDetailsBody -> LTBetItem)
    // We need a prop for the current  LTBetItem instance,
    // And a prop for the next, descendant LTBetItem instance
    receivedIsLastTicketsData: {
      type: Boolean,
      default: true,
    },
    toPassIsLastTicketsData: {
      type: Boolean,
      default: true,
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
    detailsMode: {
      type: String,
      default: 'standalone',
    },
    statusPosition: {
      type: String,
      default: 'left',
    },
    isDetailsMode: {
      type: Boolean,
      default: false,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
    sevenPlatform: {
      type: Boolean,
      default: false,
    },
    accordionHeader: {
      type: Boolean,
      default: true,
    },
    activeAccordionType: {
      type: String,
      default: 'multi',
    },
    activeAccordion: {
      type: String,
      default: '',
    },
    isCloseButtonAllowed: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['openDetails', 'closeDetails'],
  data() {
    return {
      rebetVisible: false,
      ticketDetails: null,
      isModalVisible: false,
      isAccordionExpanded: false,
      numbersGames: ['LuckyX', 'LuckySix', 'Keno', 'NextSix', 'luckysix', 'luckyx'],
      colors: {
        LuckySix: ['#dd1f1f', '#1cc51c', '#0087ff', '#a82def', '#844e14', '#efc82d', '#cb5b00', '#9a9a9a'],
        LuckyX: ['#DD1F1F', '#2962FF', '#67B627', '#FFE400', '#8C00B4'],
        Keno: ['#1565C0'],
        NextSix: ['#2962ff', '#67b627', '#8c00b4', '#dd1f1f', '#cb5b00', '#ababab'],
      },
    };
  },
  computed: {
    ...mapGetters({
      isRebet: 'betslip/isRebet',
      translations: 'translations',
      gamesBetslipConfig: 'gamesBetslip/config',
      config: 'config',
      isDesktop: 'isDesktop',
      formatNumber: 'gamesBetslip/decimalFormatNumber',
      isFreeBetMode: 'isFreeBetMode',
      isAudioOn: 'isAudioOn',
    }),
    isIndependable() {
      return this.detailsMode === 'standalone' || this.detailsMode === 'modal';
    },
    isComboTicket() {
      return toLower(this.ticket.ticketType) === 'combo';
    },
    ticketRebetAllowed() {
      return this.gamesBetslipConfig.isTicketRebetAllowed;
    },
    gridVariation() {
      return this.config.productName === 'Keno' ? 'square' : 'circle';
    },
    formatDate() {
      return format(new Date(this.ticket.createdAt), 'dd.M.yyyy - HH:mm');
    },
  },
  methods: {
    ...mapActions({
      clearBetslip: 'betslip/clearBetslip',
      rebet: 'gamesBetslip/rebet',
    }),
    isBetArray(bet) {
      return isArray(bet);
    },
    isDefined(value) {
      return !isNil(value);
    },
    isTicketActive(status) {
      return status === 'OPEN' || status === 'IN_PLAY';
    },
    isNumbersBet(bet) {
      // Check if the game is number bassed and if the bet is number based
      return this.numbersGames.includes(this.config.productName) && (String(bet.outcome).includes(',') || Number(bet.outcome));
    },
    getBallColor(ball) {
      const { productName } = this.config;
      if (productName === 'NextSix') {
        const ballsPerColor = 8;
        const colorIndex = Math.floor((ball - 1) / ballsPerColor) % this.colors[productName].length;
        return this.colors[productName][colorIndex];
      }
      return this.colors[productName][(ball - 1) % (this.colors[productName].length)];
    },
    filteredOutcome(bet) {
      return String(bet.outcome).split(',').map(Number);
    },
    isBallActive(ball, bet) {
      return isArray(bet.eventValue)
        ? ((bet.eventValue && bet.eventValue.includes(ball))
              || this.isBallDrawn?.(ball, bet.round, bet))
        : bet.eventValue.split(',').map(item => parseInt(item, 10)).includes(ball)
            || this.isBallDrawn?.(ball, bet.round, bet);
    },
    isMultilineBet({ outcome }) {
      if (includes(outcome, ',')) {
        const currentBet = outcome.split(',');

        if (currentBet.length > 10) {
          return true;
        }
      }

      return false;
    },
    getDateTime(value) {
      return format(parseISO(value), 'dd.MM.yyyy');
    },
    doRebet() {
      this.rebet(this.ticket).then(
        () => {
          this.$root.$emit('TicketRebet');
          eventBus.$emit('TicketRebet');
        },
      );
      this.toggleRebet(false);
    },
    toggleRebet(visible) {
      if (!this.isDesktop) {
        return;
      }
      this.rebetVisible = visible;
    },
    setTicketDetails(ticket) {
      this.ticketDetails = ticket;

      if (this.isIndependable) {
        this.toggleModal(true);
      } else if (this.detailsMode === 'accordion') {
        this.toggleAccordion();
      } else {
        this.$root.$emit('openDetails', this.ticketDetails);
        eventBus.$emit('openDetails', this.ticketDetails);
      }
    },
    toggleModal(visibility) {
      this.isModalVisible = visibility;
    },
    toggleAccordion() {
      this.isAccordionExpanded = this.activeAccordionType === 'multi'
        ? !this.isAccordionExpanded : this.activeAccordion === this.ticket.id;
    },
    closeAccordion() {
      this.isAccordionExpanded = false;
    },
    betTypeLabel(type, bet) {
      if (type === 'Forecast' || type === 'Reverse Forecast') {
        return type === 'Forecast' ? this.translations.greyhound_forecast
          : `${this.translations.greyhound_reverse} ${this.translations.greyhound_forecast}`;
      }
      if (bet.type === 14) {
        return type;
      }
      return this.translations[`general_${type.toLowerCase()}`] || capitalizeFirstLetter(type);
    },
    betStatusLabel(status) {
      return status.name.toUpperCase();
    },
    ticketDetailBetStatusLabel(status) {
      if (status.toLowerCase() === 'payedout') {
        return this.translations.general_ticket_status_won?.toUpperCase();
      }
      if (status.toLowerCase() === 'closed') {
        return this.translations.general_ticket_canceled?.toUpperCase();
      }
      return this.translations[`general_ticket_status_${status.toLowerCase()}`].toUpperCase();
    },
    wonLabel() {
      return (this.translations.general_ticket_status_won).toUpperCase();
    },
    getOdd(bet) {
      if ((bet.type === 13 && bet.status.id !== '5') || !bet.odd) {
        return '';
      }
      const odd = Number(bet.odd);
      return odd.toFixed(2);
    },
  },
  mounted() {
    if (!this.isDesktop) {
      this.rebetVisible = true;
    }
  },
  activated() {
    // Keep alive caches isModalVisible,
    // So we have to toggle it back default.
    // See BetslipAreaA component
    if (this.isModalVisible) this.isModalVisible = false;
  },
  watch: {
    activeAccordion() {
      this.toggleAccordion();
    },
    ticket(newState, previousState) {
      if (this.isAudioOn && this.$AudioManager) {
        if (previousState.status.value === 'IN_PLAY'
          && (newState.status.value === 'PAYEDOUT' || newState.status.value === 'WON')
        ) {
          this.$AudioManager.play('win');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../../styles/animations.scss';
.last-tickets-item {
  background-color: var(--background-1);
  margin-bottom: 4px;
  &.ticket-details {
    margin-bottom: 0px;
  }

  .status-lost {
    color: var(--system-negative);
  }

  .status-won,
  .status-payedout {
    color: var(--system-positive);
  }

  .status-open,
  .status-in_play,
  .status-closed,
  .status-pending {
    color: var(--system-neutral);
  }
  .status-open {
    .super-bonus {
      color: var(--system-neutral) !important;
    }
  }

  .bet-value.status-right {
    display: flex;
    flex-direction: row-reverse;
    align-content: space-between;
    justify-content: space-between;
  }
  &:hover .footer {
    background-color: var(--card-hover);
    transition: all 0.3s ease;
  }

  .body {
    background-color: var(--background-1);
    &:hover .bet-item {
      background-color: var(--card-hover);
    }
    .outcome-wrap {
      display: flex;
      align-items: center;
      .bet-outcome {
        &::v-deep .ball {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        &::v-deep .active {
          color: var(--text-primary-1);
        }
        &::v-deep .balls-row {
          display: flex;
        }
      }
    }

    .bet-item {
      width: 100%;
      display: flex;
      background-color: var(--card);
      text-align: left;
      padding: 13px 16px;
      margin-bottom: 1px;
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.3s ease;
      min-height: 60px;
      &.seven-platform {
        padding: 0;
      }

      &.multiline-bet {
        height: fit-content;
      }

      &.free-bet {
        border-right: 3px solid var(--free-bet);
      }

      &:hover {
        background-color: var(--card-hover);
      }
      &.active {
        background-color: var(--ticket-background-highlight);
      }

      .rebet {
        font-size: 12px;
        cursor: pointer;
        color: var(--text-primary-1);
        float: right;
        text-transform: uppercase;
        transition: opacity .3s ease-in-out;
      }

      .bet-value {
        width: 100%;

        .status {
          font-size: 12px;
          font-weight: 500;
        }

        .label {
          margin-bottom: 6px;

          &.bet-outcome {
            margin-bottom: 0px;
            position: relative;
            .placeholders {
              position: absolute;
              display: flex;
              flex-wrap: wrap;
              top: 0;
            }
            ::v-deep .button {
              width: 30px;
              height: 30px;
              margin: 0 3px 3px;
              color: rgba(var(--text-primary-1), 0.3);
              font-size: 14px;
              line-height: 18px;
            }
            .outcome-wrapper {
              z-index: 1;
              position: relative;
              ::v-deep .button.active{
                animation-name: emphasize;
                animation-duration: .5s;
                animation-timing-function: cubic-bezier(0.33, 0.00, 0.67, 1.00);
                color: var(--text-primary-1);
              }
            }
          }
        }

        .bet-status {
          float: left;
          width: 50%;
          &.super-bonus {
            color: var(--system-positive);
            span.lost {
              color: var(--system-negative);
            }
          }
        }

        .normal {
          color: var(--text-primary-2);
        }

        .bolded {
          color: var(--text-primary-1);
          font-weight: 800;
          margin-left: 3px;
        }

        .date-time-wrapper {
          display: inline;
        }

        .bet-odd {
          display: block;
          clear: both;
          text-align: right;
          color: var(--text-primary-1);
        }

        .label-last {
          margin-bottom: 0;
        }

        .title {
          font-size: 14px;
          color: var(--text-primary-3);
          margin-right: 4px;
        }

        .subtitle {
          font-size: 12px;
          color: var(--text-primary-2);
          width: 100%;
          display: flow-root;
          &.ticket-details {
            width: 100%;
          }
        }
      }
      .ticket-header {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        height: 58px;
        justify-content: space-between;
        padding: 0 8px;
        width: 100%;
        .opacity-primary {
          opacity: 0.6;
        }

        &.border-left-color-status-open {
          border-left: 4px solid var(--system-neutral);
        }
        &.border-left-color-status-payedout,
        &.border-left-color-status-won {
          border-left: 4px solid var(--system-positive);
        }
        &.border-left-color-status-lost {
          border-left: 4px solid var(--system-negative);
        }
        &.border-left-color-status-cancel {
          border-left: 4px solid #efefef;
        }
        .ticket-header-left {
          text-transform: uppercase;
          white-space: nowrap;
        }
        .ticket-header-status {
          padding-right: 2px;
          font-family: 'Roboto';
        }
        .ticket-header-right {
          text-transform: capitalize;
          .ticket-header-payin-wrapper,
          .ticket-header-payout-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }
          .ticket-header-payout-wrapper{
            flex-wrap: wrap;
          }
          .ticket-header-payin,
          .ticket-header-payout,
          .ticket-header-tax {
            span {
              &:first-child {
                &:after {
                  content: ':';
                  margin-right: 3px;
                }
              }
            }
          }
          .ticket-header-tax {
            &:before {
              content: '|';
              padding-left: 5px;
              padding-right: 3px;
            }
          }
          .ticket-header-payin-wrapper {
            .ticket-header-tax {
              &:before {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    height: 40px;
    background-color: var(--card-section);
    float: left;
    width: 100%;
    font-size: 12px;
    margin-bottom: 1px;
    padding: 0 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: var(--card-hover);
    }
    &:hover ~ .body .bet-item {
      background-color: var(--card-hover);
    }

    .payout,
    .ticket-status {
      float: left;

      .value {
        line-height: 41px;
        float: left;
      }
    }

    .rebet {
      line-height: 40px;
      cursor: pointer;
      color: var(--text-primary-1);
      float: right;
      text-transform: uppercase;
      transition: opacity .3s ease-in-out;
    }
  }
  .bonus-wrapper {
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    float: left;
    width: 100%;
    margin-bottom: 4px;
    background-color: var(--system-neutral);
    color: var(--text-secondary-1);
    padding: 0 16px;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to,
  .fade-leave {
    opacity: 1;
  }
}
</style>
